<template>
  <div class="swiper-container process" key="2">
    <div class="swiper-wrapper process-list">
      <div
        class="swiper-slide p-station"
        v-for="item in processList"
        :key="item.year"
      >
        <span class="p-year">{{ item.year }}</span>
        <span class="p-lion"></span>
        <span class="p-things">{{ item.things }}</span>
      </div>
    </div>
    <div
      class="swiper-pagination swiper-pagination-bullets"
      style="bottom:88px"
    ></div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  name: "process",
  data() {
    return {
      // processLeft: 200,
      // num: 5
    };
  },
  computed: {
    processList() {
      const tempData = [
        {
          year: "2023",
          things: this.$t("about.process.note23")
        },
        {
          year: "2022",
          things: this.$t("about.process.note22")
        },
        {
          year: "2021",
          things: this.$t("about.process.note21")
        },
        {
          year: "2020",
          things: this.$t("about.process.note20")
        },
        {
          year: "2019",
          things: this.$t("about.process.note19")
        },
        {
          year: "2018",
          things: this.$t("about.process.note18")
        },
        {
          year: "2017",
          things: this.$t("about.process.note17")
        },
        // {
        //   year: "2016",
        //   things: this.$t("about.process.note16")
        // },
        {
          year: "2015",
          things: this.$t("about.process.note15")
        },
        // {
        //   year: "2014",
        //   things: this.$t("about.process.note14")
        // },
        {
          year: "2012",
          things: this.$t("about.process.note12")
        },
        {
          year: "2010",
          things: this.$t("about.process.note10")
        }
      ];
      return tempData;
    }
  },
  methods: {
    _initSwiperProcess() {
      // this.processLeft=(document.body.clientWidth - 1300) / 2,
      // this.num=document.body.clientWidth / 317.5
      this.mySwiperProcess = new Swiper(".process", {
        slidesOffsetBefore: 200,
        slidesOffsetAfter: 0,
        slidesPerView: 5,
        centeredSlidesBounds: true
      });
    },
    _updateSwiperProcess() {
      this.$nextTick(() => {
        this.mySwiperProcess.update(true); //swiper update的方法
      });
    },
    swiperUpdateProcess() {
      if (this.mySwiperProcess) {
        //节点存在
        this._updateSwiperProcess(); //更新
      } else {
        this._initSwiperProcess(); //创建
      }
    }
  },
  watch: {
    //通过props传来的数据 和 组件一加载节点就创建成功 二者不是同步，实时监听的swiper(传递的值)的变化
    swiper() {
      this.swiperUpdateProcess();
    }
  },
  mounted() {
    
    this.swiperUpdateProcess(); //页面一加载拉去数据创建节点
  }
};
</script>

<style lang="scss">
.swiper-container {
  width: 100%;
}
.process {
  padding: 80px 0 65px 0;
  .swiper-wrapper {
    width: 100%;
    margin: auto;
    height: 100%;
    .swiper-slide {
      width: 327px;
    }
  }
  .process-list {
    display: flex;
    .p-station {
      flex: 1;
      span {
        display: block;
      }
      .p-year {
        padding-bottom: 34px;
        height: 39px;
        font-size: 30px;
        font-family: YouSheBiaoTiHei;
        color: rgba(34, 34, 34, 1);
        line-height: 39px;
      }
      .p-lion {
        position: relative;
        width: 100%;
        height: 1px;
        background-color: #dddddd;
        &:before {
          display: block;
          position: absolute;
          left: 0;
          top: -10px;
          content: "";
          width: 19px;
          height: 19px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(221, 221, 221, 1);
          border-radius: 50%;
        }
      }
      .p-things {
        padding-top: 34px;
        padding-right: 70px;
        padding-bottom: 20px;
        width: 257px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 30px;
      }
    }
    .p-station:last-child {
      .p-lion {
        height: 0;
      }
    }
  }
}
</style>
