<template>
  <div class="swiper-container team">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, index) in listImg"
        :key="`team${index}`"
      >
        <img :src="item" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import team1 from "@/assets/images/team/1.jpg";
import team2 from "@/assets/images/team/2.jpg";
import team3 from "@/assets/images/team/3.jpg";
import team4 from "@/assets/images/team/4.jpg";
import team5 from "@/assets/images/team/5.jpg";
import team6 from "@/assets/images/team/6.jpg";
import team7 from "@/assets/images/team/7.jpg";
import team8 from "@/assets/images/team/8.jpg";
// import team9 from "@/assets/images/team/9.jpg";
// import team10 from "@/assets/images/team/10.jpg";
export default {
  name: "OurTeam",
  data() {
    return {
      mySwiper: null,
      listImg: [
        team1,
        team2,
        team3,
        team4,
        team5,
        team6,
        team7,
        team8,
        // team9,
        // team10
      ]
    };
  },
  methods: {
    _initSwiper() {
      this.mySwiper = new Swiper(".team", {
        loop: true,
        loopedSlides: 3,
        speed: 600, // 切换速度
        spaceBetween: 86,
        centeredSlides: true,
        slidesPerView: "auto",
        autoplay: {
          // 自动切换
          delay: 3000, // 自动切换的时间间隔
          stopOnLastSlide: false, // 如果设置为true,当切换到最后一个slide时停止自动切换(loop模式下无效)
          disableOnInteraction: false // 用户操作swiper之后,是否禁止autoplay.默认为true:停止
        }
      });
    },
    _updateSwiper() {
      this.$nextTick(() => {
        this.mySwiper.update(true); //swiper update的方法
      });
    },
    swiperUpdate() {
      if (this.mySwiper) {
        //节点存在
        this._updateSwiper(); //更新
      } else {
        this._initSwiper(); //创建
      }
    }
  },
  watch: {
    //通过props传来的数据 和 组件一加载节点就创建成功 二者不是同步，实时监听的swiper(传递的值)的变化
    swiper() {
      this.swiperUpdate();
    }
  },
  mounted() {
    this.swiperUpdate(); //页面一加载拉去数据创建节点
  }
};
</script>

<style lang="scss">
.team {
  width: 100%;
  .swiper-wrapper {
    width: 100%;
    margin: auto;
    height: 100%;
    .swiper-slide {
      background-size: cover;
      width: 860px;
      img {
        width: 860px;
        height: 100%;
      }
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 43px;
      height: 4px;
      border-radius: 2px;
      background: rgba(229, 229, 229, 1);
    }
    .swiper-pagination-bullet-active {
      background: rgba(3, 3, 3, 1);
    }
  }
}
.team {
  margin-top: 68px;
  padding-bottom: 100px;
}
</style>
