<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <Header title="about"></Header>
    <BannerTitle title="about"></BannerTitle>
    <!-- 公司简介 -->
    <div class="aboutBox">
      <div class="contBox wow slideInUp">
        <img src="@/assets/images/logo3.png" alt="" />
        <div>
          <p>
            {{ $t("about.au_article1") }}
          </p>
          <p>
            {{ $t("about.au_article2") }}
          </p>
          <p>
            {{ $t("about.au_article3") }}
          </p>
          <p>
            {{ $t("about.au_article4") }}
          </p>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="partnersBox">
        <Title
          titleChina="合作伙伴"
          titleEng="COOPERATION"
          class="wow slideInUp"
        ></Title>
        <div class="title wow slideInUp">
          {{ $t("about.partnerIntro") }}
        </div>
        <div class="partners wow slideInUp">
          <img src="@/assets/images/zijietiaodong.png" alt="" />
          <img src="@/assets/images/baidu.png" alt="" />
          <img src="@/assets/images/alibaba.png" alt="" />
          <img src="@/assets/images/tengxun.png" alt="" />
          <img src="@/assets/images/honor.png" alt="" />
          <img src="@/assets/images/vivo.png" alt="" />
          <img src="@/assets/images/huawei.png" alt="" />
          <img src="@/assets/images/jd.png" alt="" />
          <img src="@/assets/images/kuaishou.png" alt="" />
          <img src="@/assets/images/liebao.png" alt="" />
          <img src="@/assets/images/qutoutiao.png" alt="" />
          <img src="@/assets/images/renminwang.png" alt="" />
          <img src="@/assets/images/huanqiu.png" alt="" />
          <img src="@/assets/images/sina.png" alt="" />
          <img src="@/assets/images/yidian.png" alt="" />
          <img src="@/assets/images/pdd.png" alt="" />
          <img src="@/assets/images/maotai.png" alt="" />
          <img src="@/assets/images/huaxishengwu.png" alt="" />
          <img src="@/assets/images/lizhi.png" alt="" />
          <img src="@/assets/images/kouzijiao.png" alt="" />
          <img src="@/assets/images/taiping.png" alt="" />
          <img src="@/assets/images/xingbake.png" alt="" />
          <img src="@/assets/images/aiqiyi.png" alt="" />
          <img src="@/assets/images/58.png" alt="" />
          <img src="@/assets/images/xiecheng.png" alt="" />
          <img src="@/assets/images/changfazhan.png" alt="" />
          <img src="@/assets/images/ellipsis.png" alt="" class="ellipsis"/>
        </div>
      </div>
      <!-- 我们的团队 -->
      <div class="teamBox">
        <Title titleChina="我们的团队" titleEng="TEAM" class="wow slideInUp"></Title>
        <OurTeam class="wow slideInUp"></OurTeam>
      </div>
      <!-- 核心价值观 -->
      <div class="coreBox">
        <img src="@/assets/images/corevalue.png" alt="" />
        <div class="core wow slideInUp">
          <Title titleChina="核心价值观" titleEng="CORE VALUES"></Title>
          <ul class="wow slideInUp">
            <li>
              <img src="@/assets/images/chengxinzhengzhi.png" alt="" />
              <p>{{ $t("about.values.tit1") }}</p>
              <span>{{ $t("about.values.tips1") }}</span>
            </li>
            <li>
              <img src="@/assets/images/xiaobukuaipao.png" alt="" />
              <p>{{ $t("about.values.tit2") }}</p>
              <span>{{ $t("about.values.tips2") }}</span>
            </li>
            <li>
              <img src="@/assets/images/xiezuogongying.png" alt="" />
              <p>{{ $t("about.values.tit3") }}</p>
              <span>{{ $t("about.values.tips3") }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="processBox">
        <Title
          titleChina="发展历程"
          titleEng="PROCESS"
          class="wow slideInUp"
        ></Title>
        <Process class="wow slideInUp"></Process>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Title from "../../components/title.vue";
import Footer from "../../components/footer.vue";
import Process from "./Process.vue";
import OurTeam from "./OurTeam.vue";
export default {
  name: "about",
  components: {
    Header,
    BannerTitle,
    Title,
    Process,
    Footer,
    OurTeam
  }
};
</script>

<style lang="scss">
.aboutBox {
  margin-top: 61px;
  .contBox {
    width: 1053px;
    height: 225px;
    margin: 0 auto 250px;
    img {
      float: left;
      width: 170px;
    }
    div {
      width: 813px;
      float: right;
      p {
        line-height: 32px;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        text-indent: 2em;
      }
    }
  }
  .partnersBox {
    height: 1000px;
    padding: 28px 0 98px 0;
    background: rgba(250, 250, 250, 1);
    .title {
      width: 848px;
      text-align: center;
      line-height: 32px;
      font-weight: 400;
      margin: 27px auto 0;
      color: rgba(102, 102, 102, 1);
    }
    .partners {
      width: 1300px;
      margin: 14px auto 0;
      img {
        margin-top: 46px;
        width: 225px;
        height: 76px;
        margin-right: 31px;
      }
      .ellipsis{
        width: 60px;
        height: 11px;
        vertical-align: top;
        margin-top: 73px;
        margin-left: 92px;
      }
      img:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  .teamBox {
    margin-top: 101px;
  }
  .coreBox {
    position: relative;
    img {
      width: 100%;
    }
    .core {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      ul {
        width: 1152px;
        margin: 89px auto 0;
        display: flex;
        justify-content: space-between;
        li {
          width: 272px;
          img {
            width: 72px;
            display: block;
            margin: auto;
          }
          p {
            text-align: center;
            font-size: 26px;
            font-weight: 600;
            color: rgba(34, 34, 34, 1);
            line-height: 37px;
            margin: 16px 0 13px;
          }
          span {
            display: block;
            text-align: center;
            font-weight: 400;
            color: rgba(34, 34, 34, 1);
            line-height: 22px;
          }
        }
      }
    }
  }
  .processBox {
    margin: 40px 0 50px 0;
  }
}
</style>
